export const seoText = {
  works: {
    title: {
      ja: 'Works',
      en: 'Works',
    },
    description: {
      ja:
        '松本杏菜のアート作品。主な作品には「舞子」「火からの距離」などがある。京都を拠点に、分野に縛られず、写真・映像・身体表現など様々な表現方法で幅広く活動している。',
      en:
        'Anna Matsumoto\'s art works. Her major art works are "Maiko" and "Distance from fire". Active in various fields such as photography, film and performance.',
    },
  },
  notes: {
    title: {
      ja: 'Notes',
      en: 'Notes',
    },
    description: {
      ja: 'ノート',
      en: 'Notes',
    },
  },
};
