import * as React from 'react';
import { graphql } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import { Link } from '../utils/link';
import {
  Language,
  getPathWithLanguage,
  defaultLangKey,
} from '../data/languages';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { models } from '../../models';
import { seoText } from '../data/seo';

type Node = {
  node: {
    id: string;
    node_locale: Language;
    title: string;
    slug: string;
    featuredImage: {
      fluid: FluidObject;
    };
  };
  index: number;
};

type Props = {
  data: {
    allContentfulWork: {
      edges: Node[];
    };
  };
  location: Location;
};

function Work({
  node: { title, featuredImage, node_locale, slug },
  index,
}: Node) {
  const getRandomGradientBg = (index?: number) => {
    const gradients = [
      ['to', 'right', '#ffafbd', '#ffc3a0'],
      ['to', 'right', '#a1ffce', '#faffd1'],
      ['to', 'bottom', '#000428', '#004e92'],
      ['to', 'top', '#e0eafc', '#cfdef3'],
      ['to', 'top', '#b993d6', '#8ca6db'],
      ['to', 'top', '#dae2f8', '#d6a4a4'],
      ['to', 'right', '#b79891', '#94716b'],
      ['to', 'top', '#2b5876', '#4e4376'],
      ['to', 'left', '#5433ff', '#20bdff', '#a5fecb'],
    ];
    const randomIndex =
      index !== undefined
        ? index % gradients.length
        : Math.floor(Math.random() * (gradients.length - 1));
    const [, angle, ...colors] = gradients[randomIndex];

    return `linear-gradient(to ${angle}, ${colors.join(', ')})`;
  };

  return (
    <div className="layout__item desk-one-quarter lap-and-up-one-third--square palm-one-half mobile-one-whole works__item">
      <Link
        to={getPathWithLanguage(node_locale, 'works', slug)}
        paintDrip={true}
      >
        {!!featuredImage?.fluid ? (
          <BackgroundImage
            Tag="article"
            className={'post loaded'}
            fluid={[featuredImage.fluid, getRandomGradientBg()]}
          >
            <h1 className="post__title">{title}</h1>
            <span className="post__overlay" />
          </BackgroundImage>
        ) : (
          <article
            className={'post loaded'}
            style={{ backgroundImage: getRandomGradientBg() }}
          >
            <h1 className="post__title">{title}</h1>
            <span className="post__overlay" />
          </article>
        )}
      </Link>
    </div>
  );
}

const WorksPage = ({ data, location }: Props) => {
  const lang =
    data.allContentfulWork.edges[0]?.node?.node_locale || defaultLangKey;
  const featuredImage = data.allContentfulWork.edges.filter(
    edge => !!edge.node.featuredImage
  )?.[0]?.node.featuredImage?.fluid?.src;
  return (
    <Layout
      lang={lang}
      location={location}
      pathnameWithoutLang={models.static?.works?.path || ''}
    >
      <SEO
        featuredImage={featuredImage}
        description={seoText.works.description[lang]}
        lang={lang}
        title={seoText.works.title[lang]}
      />
      <div>
        <div className="band--small">
          <div className="wrapper">
            <div className="layout layout--tiny">
              {data.allContentfulWork.edges.map(({ node }, index) => (
                <Work node={node} key={node.id} index={index} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default WorksPage;

export const pageQuery = graphql`
  query worksQuery($lang: String!) {
    allContentfulWork(
      filter: { node_locale: { eq: $lang } }
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          id
          node_locale
          title
          slug
          publishDate
          featuredImage {
            fluid(maxWidth: 500) {
              sizes
              src
              srcSet
            }
          }
        }
      }
    }
  }
`;
